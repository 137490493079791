import React, { useState, Fragment, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { callMsGraphUsers } from "../../services/graph";
import ChangeGroupRow from "../payroll/payrollChangeGroupRow";
import { ChangeGroupWarning } from "./payrollChangeGroupWarning";
import { UndoRedo } from "@mdxeditor/editor";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { useAuthentication } from "../../contexts/AuthContext";
import { Heading } from "cai-fusion";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const PayrollChangeGroup = props => {

    const { impersonating, graphData, apiAccessToken, graphAccessToken } = useAuthentication();

    const [ownedChangeGroups, setOwnedChangeGroups] = useState(props.ownedChangeGroups)
    const [assignedChangeGroups, setAssignedChangeGroups] = useState(props.assignedChangeGroups)
    const [seeAllChangeGroups, setSeeAllChangeGroups] = useState(props.seeAllChangeGroups)
    const [warnings, setWarnings] = useState([])
    const [changeGroupSet, setChangeGroupSet] = useState("ownedChangeGroups")
    const [loadingOrgs, setLoadingOrgs] = useState(true)
    const [errors, setErrors] = useState([])
    const [meritChanges, setMeritChanges] = useState([])
    const [changeGroupRows, setChangeGroupRows] = useState(null)
    const [seeAll, setSeeAll] = useState(props.seeAll);

    const upn = impersonating ? impersonating.userPrincipalName : graphData?.userPrincipalName;

    const [regionFilter, setRegionFilter] = useState("Global");
    const [usaVisible, setUSAVisible] = useState("false");
    const [indVisible, setINDVisible] = useState("false");
    const [phlVisible, setPHLVisible] = useState("false");

    const [displayChangeGroups, setDisplayChangeGroups] = useState([])

    //This handles the changing of the "Owned" vs. "Assigned tabs"
    const changeGroupTabChangeHandler = (newChangeGroupSet) => {
        setChangeGroupSet(newChangeGroupSet)

        if (newChangeGroupSet == "assignedChangeGroups") {
            props.changeGroupChange(assignedChangeGroups);
            setErrors([]);
            setMeritChanges(getMeritChanges(assignedChangeGroups));
            setDisplayChangeGroups(digestChangeGroups(assignedChangeGroups));
        } else if (newChangeGroupSet == "seeAllChangeGroups") {
            props.changeGroupChange(seeAllChangeGroups);
            setErrors([]);
            setMeritChanges(getMeritChanges(seeAllChangeGroups));
            setDisplayChangeGroups(digestChangeGroups(seeAllChangeGroups));
        } else {
            props.changeGroupChange(ownedChangeGroups);
            setErrors([]);
            setMeritChanges(getMeritChanges(ownedChangeGroups));
            setDisplayChangeGroups(digestChangeGroups(ownedChangeGroups));
        }

    }

    //Transforms the data into a more display friendly format
    const digestChangeGroups = (changeGroup, level = 1, parent = null) => {
        var tmpChangeGroups = []


        changeGroup?.map((x) => {
            var newChangeGroup = ({
                level: level,
                id: x.changeGroupId,
                changeGroupKey: x.changeGroupKey,
                orgName: x.name.split(` (`)[0],
                orgOwner: x.name.split(` (`)[1],
                budget: x.budget,
                currentBaseComp: x.currentTargetComp,
                increasePercent: x.currentTargetComp && x.currentTargetComp > 0 ? (x.proposedTargetComp - x.currentTargetComp) / x.currentTargetComp : 0,
                increaseAmt: x.proposedTargetComp - x.currentTargetComp,
                proposedBaseComp: x.proposedTargetComp,
                error: getChangeGroupErrors(x),
                extended: level == 1,
                visible: level == 1 || level == 2,
                hasChildren: x.childChangeGroups.length > 0,
                parent: parent,
                assignee: x.assignedUPN
            });
            newChangeGroup.children = x.childChangeGroups.length > 0 ? digestChangeGroups(x.childChangeGroups, level + 1, newChangeGroup) : null;
            tmpChangeGroups.push(newChangeGroup);
        })

        return tmpChangeGroups
    }
    /*
    const getAllMeritChanges = (changeGroup) => {

        var newMeritChanges = meritChanges

        changeGroup?.meritChanges?.push((x) => {
            newMeritChanges.push(x)
        })

        setMeritChanges(newMeritChanges)

        getAllMeritChanges(changeGroup?.children);

    }
    */
    //Gets the errors for the change groups to-do, verify this is all the errors we want
    const getChangeGroupErrors = (changeGroup) => {
        const MeritChanges = changeGroup.meritChanges
        var error = null
        var issueMeritChanges = []

        //Checking if the group is not assigned to the user, and the user is on the "See All" list 
        //or the change is not assigned to Tom but is assigned to someone else on the see all list
        if ((changeGroup.assignedUPN.localeCompare(upn, 'en-us', { sensitivity: 'accent' }) && !seeAll)
            || (changeGroup.assignedUPN.localeCompare("tom.salvaggio@cai.io", 'en-us', { sensitivity: 'accent' })
                && changeGroup.assignedUPN.localeCompare(upn, 'en-us', { sensitivity: 'accent' }) && seeAll)) {
            error = "Organization is assigned to " + changeGroup.assignedUPN;
        }

        if (error == null) {
            MeritChanges?.map((x) => {
                if (!x.reviewed && x.isEligible) {
                    issueMeritChanges.push(x.id)
                    error = "Organization has Merit Changes that are not filled out."
                }
            })
        }

        if (error == null)
            changeGroup.childChangeGroups?.map((x) => {
                if (getChangeGroupErrors(x) != null)
                    error = "Organization has Sub-Orgs that have not been finished."
            })


        if (error != null) {
            var newErrors = errors
            newErrors.push({
                issueMeritChanges: issueMeritChanges,
                error: error
            })
            setErrors(newErrors)
        }

        return error;

    }

    const onExtensionHandler = (changeGroupObject = displayChangeGroups, idForChange) => {

        var newObjs = [];

        changeGroupObject?.forEach((el) => {
            if (el.parent != null) {
                el.visible = el.parent?.visible && el.parent?.extended;
            }
            if (el.id == idForChange) {
                el.extended = !el.extended;
            }
            el.children = onExtensionHandler(el.children, idForChange);

            newObjs.push(el);
        });

        return newObjs
    }

    //maps Change Group Object into "ChangeGroupRow" Objects
    const mapChangeGroupRows = (changeGroupObject) => {

        //console.log(changeGroupObject);

        if (changeGroupObject == [] || changeGroupObject == null) {
            return (<></>)
        }

        return (
            <>
                {changeGroupObject != [] && changeGroupObject != null ? changeGroupObject.map((x) => (
                    <>
                        <ChangeGroupRow
                            level={x.level}
                            id={x.id}
                            changeGroupKey={x.changeGroupKey}
                            orgName={x.orgName}
                            orgOwner={x.orgOwner}
                            currentBaseComp={x.currentBaseComp}
                            increasePercent={x.increasePercent}
                            increaseAmt={x.increaseAmt}
                            proposedBaseComp={x.proposedBaseComp}
                            error={x.error}
                            hasChildren={x.hasChildren}
                            visible={x.visible}
                            extended={x.extended}
                            onExtend={(y) => {
                                setDisplayChangeGroups(onExtensionHandler(displayChangeGroups, y));
                            }}
                            obj={x}
                            upn={upn}
                            seeAll={seeAll}
                        />
                        {mapChangeGroupRows(x.children)}
                    </>
                )) : <></>}
            </>
        )
    }

    const getChangeGroupIds = (changeGroupObj) => {
        var changeGroupIds = []

        if (changeGroupObj != null && changeGroupObj?.length > 0) {
            changeGroupObj.map((x) => {
                changeGroupIds.push(x.id);

                changeGroupIds = changeGroupIds.concat(getChangeGroupIds(x.childChangeGroups));
            });
        }

        console.log(changeGroupIds);
        return changeGroupIds;
    }

    const getMeritChanges = (changeGroupObj) => {

        var tmpMeritChanges = [];

        changeGroupObj?.map(x => {
            x?.meritChanges?.map((z) => {
                tmpMeritChanges.push(z)
            })
            getMeritChanges(x?.childChangeGroups).map((y) => {
                tmpMeritChanges.push(y)
            });
        });

        return tmpMeritChanges;
    }

    const filterMeritChanges = (changeGroupObj) => {

        var changeGroupIds = getChangeGroupIds(changeGroupObj);

        var tmpMeritChanges = meritChanges.filter(x => changeGroupIds.includes(x.changeGroupId))

        return tmpMeritChanges;
    }

    // Do filtering when data filter is changed
    useEffect(() => {
        var curGroups = changeGroupSet == "assignedChangeGroups" ? assignedChangeGroups.map((x) => x) : ownedChangeGroups.map((x) => x);
        var usChild = false;
        var indChild = false;
        var phlChild = false;
        curGroups.forEach(x => {
            usChild = x.childChangeGroups.filter((x) => (!x.name.includes("India") && !x.name.includes("Philippines"))).length > 0;
            indChild = x.childChangeGroups.filter((x) => x.name.includes("India")).length > 0;
            phlChild = x.childChangeGroups.filter((x) => x.name.includes("Philippines")).length > 0;
        });
        setUSAVisible(usChild || curGroups.filter((x) => (!x.name.includes("India") && !x.name.includes("Philippines"))).length > 0);
        setINDVisible(indChild || curGroups.filter((x) => x.name.includes("India")).length > 0);
        setPHLVisible(phlChild || curGroups.filter((x) => x.name.includes("Philippines")).length > 0);
    }, [changeGroupSet]);

    useEffect(() => {
        console.log(regionFilter);
        var curGroups = changeGroupSet == "assignedChangeGroups" ? assignedChangeGroups.map((x) => x) : ownedChangeGroups.map((x) => x);
        var filteredGroups = [];
        console.log(curGroups);

        curGroups.forEach(parent => {
            //For groups with children, blank out children not matching the region, and remove the blank objects
            if (parent.childChangeGroups.length > 0) {
                var filteredChildren = [];
                parent.childChangeGroups.forEach(child => {
                    //console.log(child);
                    switch (regionFilter) {
                        case "USA":
                            if (!child.name.includes("India") && !child.name.includes("Philippines")) {
                                filteredChildren.push(child);
                            }
                            break;
                        case "IND":
                            if (child.name.includes("India")) {
                                filteredChildren.push(child);
                            }
                            break;
                        case "PHL":
                            if (child.name.includes("Philippines")) {
                                filteredChildren.push(child);
                            }
                            break;
                        default:
                            filteredChildren.push(child);
                    }
                });
                console.log(filteredChildren);
                if (filteredChildren.length > 0) {
                    var newParent = JSON.parse(JSON.stringify(parent));
                    newParent.childChangeGroups = filteredChildren;
                    filteredGroups.push(newParent);
                }
            }
            // For groups without children, blank them out if they don't match the region filter
            else {
                switch (regionFilter) {
                    case "USA":
                        if (!parent.name.includes("India") && !parent.name.includes("Philippines")) {
                            filteredGroups.push(parent);
                        }
                        break;
                    case "IND":
                        if (parent.name.includes("India")) {
                            filteredGroups.push(parent);
                        }
                        break;
                    case "PHL":
                        if (parent.name.includes("Philippines")) {
                            filteredGroups.push(parent);
                        }
                        break;
                    default:
                        filteredGroups.push(parent);
                }
            }
        });
        console.log(filteredGroups);
        console.log(curGroups);
        /*switch (regionFilter){
            case "USA":
                filteredGroups = curGroups.filter((x) => (!x.name.includes("India") && !x.name.includes("Philippines")));
                console.log(filteredGroups);
                console.log("USA Only");
                break;
            case "IND":
                filteredGroups = curGroups.filter((x) => x.name.includes("India"));
                console.log(filteredGroups);
                console.log("IND Only");
                break;
            case "PHL":
                filteredGroups = curGroups.filter((x) => x.name.includes("Philippines"));
                console.log(filteredGroups);
                console.log("PHL Only");
                break;
            default:
                var filteredGroups = curGroups.map((x) => x);
                console.log(filteredGroups);
                console.log("All Regions");
        }*/

        props.changeGroupChange(filteredGroups);
        setErrors([]);
        setMeritChanges(getMeritChanges(filteredGroups));
        setDisplayChangeGroups(digestChangeGroups(filteredGroups));

    }, [regionFilter]);

    const getChangeGroupStatus = (changeGroupObj) => {
        var error = null
        var subtext = null
        var remainingMeritChanges = []
        var curBaseComp = 0;
        var propBaseComp = 0;
        var changeGroupMeritChanges = filterMeritChanges([changeGroupObj]);
        //var changeGroupMeritChanges = changeGroupObj;
        console.log(changeGroupMeritChanges);


        changeGroupMeritChanges.map((x) => {
            curBaseComp += x.curBaseComp;
            propBaseComp += x.proposedBaseComp;

            if (!x.reviewed && x.isEligible) {
                remainingMeritChanges.push(x);
            }

        })

        if (remainingMeritChanges.length > 0) {
            error = "Make sure every employee has an increase specified";
            subtext = `There are ${remainingMeritChanges.length} people in your organization that have no increase specified yet.`;
        }

        let changeGroups = changeGroupObj;


        if (error == null && displayChangeGroups.length > 0) {
            if (changeGroupObj.error == "Organization has Sub-Orgs that have not been finished.") {
                error = "Organization has Sub-Orgs that have not been finished.";
                subtext = "";
            }
        }

        if (error == null && displayChangeGroups.length > 0) {
            if ((changeGroupObj.assignee.localeCompare(upn, 'en-us', { sensitivity: "accent" }))) {
                error = "Organization is not yet assigned to you";
                subtext = "";
            }
        }

        let overBudget = false;

        if (changeGroupObj.proposedBaseComp > (changeGroupObj.budget + 1) || (changeGroupObj.proposedBaseComp / changeGroupObj.currentBaseComp > changeGroupObj.budget * 1.0001)) {
            overBudget = true;
        }

        if (error == null && displayChangeGroups.length > 0) {
            if (overBudget) {
                error = "Make sure your parent organizations are within the allocated budget increase.";
                subtext = ""
            }
        }


        return (
            <Fragment>
                <ChangeGroupWarning
                    warning={error}
                    subtext={subtext}
                    obj={changeGroupObj}
                    onSubmit={() => props.onSubmit()}
                    accessToken={apiAccessToken}
                    impersonation={impersonating}
                />
            </Fragment>
        );

    }


    /*
    const mapChangeGroupWarnings = () => {

        var displayError = [];
        var remainingMeritChanges = [];
        var changeGroups;
        var curBaseComp = 0;
        var propBaseComp = 0;
        var newErrors = errors;

        meritChanges.map((x) => {
            curBaseComp += x.curBaseComp;
            propBaseComp += x.proposedBaseComp;

            if (!x.reviewed && x.isEligible) {
                remainingMeritChanges.push(x);
            }

        })
        if (remainingMeritChanges.length > 0) {
            displayError.push({
                error: "Make sure every employee has an increase specified",
                meritChanges: remainingMeritChanges,
                subtext: `There are ${remainingMeritChanges.length} people in your organization that have no increase specified yet.`,
                changeGroupId: displayChangeGroups[0]?.id,
                obj: RootChangeGroup
            })
        }

        changeGroups = RootChangeGroup;
        var overBudget = false;

        changeGroups.map((x) => {
            if (x.proposedTargetComp > (1.02 * x.currentTargetComp)) {
                overBudget = true;
            }
        });



        if (displayError.length == 0 && displayChangeGroups.length > 0) {
            if (overBudget) {
                displayError.push({
                    error: "Make sure your parent organizations are within the allocated budget increase.",
                    subtext: ""
                });
                errors.push({
                    error: "Change Groups are above allocated budget increase"
                });
            }
        }


        /*
        if (errors.length > 0) {
            displayError.push(errors[0]);
            if (errors.length > 1) {
                displayError.push({
                    error: "More errors to be addressed",
                    issueMeritChanges: []
                })
            }
        }
        

        return (
            <>
                {displayError.map((x) => (
                    <ChangeGroupWarning
                        warning={x.error}
                        meritChanges={x.meritChanges}
                        subtext={x.subtext}
                        ccId={x.changeGroupId}
                    />
                ))}
            </>
        )
    }
    */

    useEffect(() => {
        // console.log('ownedChangeGroups')
        // console.log(props.ownedChangeGroups)
        // console.log('assignedChangeGroups')
        // console.log(props.assignedChangeGroups)
        // console.log('seeAllChangeGroups')
        // console.log(props.seeAllChangeGroups)
        setOwnedChangeGroups(props.ownedChangeGroups);
        setAssignedChangeGroups(props.assignedChangeGroups);
        setSeeAllChangeGroups(props.seeAllChangeGroups);

        if (displayChangeGroups?.length == 0 && ownedChangeGroups?.length > 0) {
            setDisplayChangeGroups(digestChangeGroups(ownedChangeGroups));//
            setMeritChanges(getMeritChanges(ownedChangeGroups));
        }

    })


    return (
        <div className="o-page-section o-page-section l-mt-md l-pt-none">
            <div className="o-page-section__content-container o-page-section__content-container--wide-fixed-width l-pt-none l-mt-neg-sm">
                <div className="l-row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="m-tab-row l-mt-sm">
                            {/* Owned Change Groups Tab */}
                            <a className={`m-tab-row__tab ${changeGroupSet == "ownedChangeGroups" ? " m-tab-row__tab--active" : ""}`}
                                onClick={(e) => {
                                    changeGroupTabChangeHandler("ownedChangeGroups");
                                    e.preventDefault();
                                }}>
                                <span className="m-tab-row__tab-title">Your Organizations</span>
                                <span className="m-tab-row__help">View only the orgs you directly manage.</span>
                            </a>
                            {/* Assigned Change Groups Tab */}
                            {assignedChangeGroups?.length > 0 ?
                                <a className={`m-tab-row__tab ${changeGroupSet == "assignedChangeGroups" ? "m-tab-row__tab--active" : ""}`}
                                    onClick={(e) => {
                                        changeGroupTabChangeHandler("assignedChangeGroups");
                                        e.preventDefault();
                                    }}>
                                    <span className="m-tab-row__tab-title">Organizations to Review</span>
                                    <span className="m-tab-row__help">View all orgs you are responsible for reviewing and approving.</span>
                                </a>
                                : ""
                            }
                            {/* See All Change Groups Tab */}
                            {seeAllChangeGroups?.length > 0 ?
                                <a className={`m-tab-row__tab ${changeGroupSet == "seeAllChangeGroups" ? "m-tab-row__tab--active" : ""}`}
                                    onClick={(e) => {
                                        changeGroupTabChangeHandler("seeAllChangeGroups");
                                        e.preventDefault();
                                    }}>
                                    <span className="m-tab-row__tab-title">All Orgs</span>
                                    <span className="m-tab-row__help">View all orgs.</span>
                                </a>
                                : ""
                            }
                        </div>
                        {/* Submit button and ChangeGroupWarning Mapping */}
                        <div className="o-block l-mt-sm">
                            <Heading size={5}>Organization Overview</Heading>
                            {/* Checks whether User's Orgs have already been submitted or if they ever even had orgs */}
                            {props.wasSubmit ? (
                                props.ownedChangeGroups.length === 0 ? (
                                    <p>Your orgs have already been submitted.</p>
                                ) : <></>
                            ) : <p>You do not have any employees.</p>
                            }
                            <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                <colgroup className="o-treegrid__colgroup">
                                    <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                    <col className="o-treegrid__col o-treegrid__col--large" span="1" />
                                    <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                </colgroup>
                                <tbody>
                                    {displayChangeGroups.map((x) => (
                                        <>
                                            {getChangeGroupStatus(x)}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/*Region Filter*/}
                        <div class="l-row l-mt-md">
                            <div class="l-one-half-col">
                                <form action="" class="m-bulk-action-menu">
                                    <div class="m-form-group">
                                        <label for="docSelector" class="a-label">View Compensation By:</label>
                                        <select
                                            name="docSelector"
                                            id="docSelector"
                                            class="a-select-input m-bulk-action-menu__select"
                                            onChange={(e) => { setRegionFilter(e.target.value); }}
                                        >
                                            <option value="Global">Global (US, India, Philippines)</option>
                                            {usaVisible && (<option value="USA">US Only</option>)}
                                            {indVisible && (<option value="IND">India Only</option>)}
                                            {phlVisible && (<option value="PHL">Philippines Only</option>)}
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="l-row">
                    <div className="col-md-12">
                        <div className="o-treegrid">
                            <div className="o-treegrid__row o-treegrid__row--header">
                            </div>
                        </div>
                        {/* Table */}
                        <table className="o-treegrid l-mt-md">
                            <colgroup className="o-treegrid__colgroup">
                                <col className="o-treegrid__col o-treegrid__col--48" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--x-wide" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                            </colgroup>
                            <thead>{/*  Table Column Headers  */}
                                <tr className="o-treegrid__row o-treegrid__row--shown">
                                    <th className="o-treegrid__cell o-treegrid__cell--header o-treegrid__cell--32"></th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Organization</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Current Total Base</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Increase %</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Increase Amount</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header">Proposed Total Base</th>
                                    <th className="o-treegrid__cell o-treegrid__cell--header"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {mapChangeGroupRows(displayChangeGroups)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayrollChangeGroup;
