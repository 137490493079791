import { useNavigate } from "react-router-dom";
import { Button, ButtonRow, Heading, ToastContext } from "cai-fusion";
import { useEffect, useContext, useState, useRef } from "react";
import { useFormsApiService } from "../../../hooks/useFormsApi";

const GovernanceForms = () => {

    const navigate = useNavigate();

    const apiServiceClient = useFormsApiService();

    const { createWarningToast } = useContext(ToastContext);

    // Form state
    const [showForm, setShowForm] = useState(false);
    // Boolean flag to conditionally render form options
    const [isSalesForce, setIsSalesForce] = useState(true);
    // Track user input
    const prevInputRef = useRef('');
    const inputPos = useRef(0);
    // The Opportunity ID, used only for the initial API call
    const [oppId, setOppId] = useState(undefined);
    // The Opportunity ID regex used to make sure it is in proper format: OPP-20201201-0001
    const oppIdRegex = /^OPP-\d{8}-\d{4,}$/i;

    // Expedited boolean
    const [shouldBeExpedited, setShouldBeExpedited] = useState(false);
    // Submission boolean
    const [isSubmitting, setIsSubmitting] = useState(false);
    // Object being altered by each of the form inputs.
    const [formObj, setFormObj] = useState({});

    // When replacing this with the actual options, please make sure the fields get updated in the select.
    const [oppOptions, setOppOptions] = useState([]);

    // Goes back and asks the first question. Resets things to default.
    const restartForm = () => {
        setShowForm(false);
        setFormObj({});
        setOppId(undefined);
        setIsSalesForce(false);
        setShouldBeExpedited(false);
    }

    // Handles the beginning of the form.
    const handleStart = async () => {
        // Use API calls to get the object and other things.
        setFormObj({});
        let types = await apiServiceClient.Forms.getOpportunityTypes();
        setOppOptions(types.Opportunity_x0020_Type.choices);
        if (isSalesForce && oppId) {
            let opp = undefined;
            try {
                opp = await apiServiceClient.Forms.getOpportunity(oppId);
            } catch (e) {
                //console.error("[FORMS] unable to get opportunity");
                createWarningToast(
                    <div>
                        <h4> Unable to find: <b>{oppId}</b></h4>
                        <p>Unable to grab the Opportunity from Salesforce. Please verify the Opportunity number exists in Salesforce, then try again.</p>
                    </div>
                );
                return;
            }
            setFormObj(opp);
            setShouldBeExpedited(opp.expedite ?? false);
        }
        setShowForm(true);
    }

    const updateData = (e) => {
        e.preventDefault();
        // Replace the field given with the changed value
        setFormObj({ ...formObj, [e.target.id]: e.target.value })
        //console.log("[FORMS] " + field + ": " +formObj[field]);
    }

    // checks the opp id and validates before starting the form
    const handleOppId = () => {
        let isValid = String(oppId) !== "OPP-00000000-0000" && String(oppId).match(oppIdRegex)
        console.log("[FORMS] OppId = ", oppId, " IsValid = ", isValid);
        if (!isSalesForce || isValid) {
            handleStart();
        }
        else {
            createWarningToast(
                <div>
                    <h4> Invalid Opportunity ID </h4>
                    <p>The inputted Opportunity ID is not formatted correctly, please resubmit with the proper formatting: <b>OPP-XXXXXXXX-XXXX</b></p>
                </div>
            )
        }
    }

    const handleCursor = (input) => {
        if (input.value === '00000000-0000') {
            input.setSelectionRange(0, 0);
            input.value = '';
        }
        console.log("[FORMS] pos: ", inputPos.current, " actual: ", input.selectionStart);
        inputPos.current = input.selectionStart;
    }

    const handleNumberChange = (e) => {
        // Remove all characters that aren't numbers and decimal points
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        // Only permit one decimal point
        if (/^\d*\.?\d*$/.test(e.target.value)) {
            updateData(e);
        }
    };

    const handleInput = (input) => {
        let inputLength = input.value.length;
        console.log("[FORMS] input length: ", inputLength);
        let formattedValue = input.value.replace(/\D/g, ''); // Remove non-digits
        let paddedValue = formattedValue;//.padEnd(12, '0'); // Pad with zeros to ensure length is 12
        // inputLength - 5 is the 
        let oppNumber = paddedValue.slice(0, 8) + (paddedValue.length > 8 ? '-' : '') + paddedValue.slice(8 /*, Math.max(inputPos.current, 12, inputLength - 5) */); // Insert hyphen
        input.style.color = input.selectionStart > 0 ? 'black' : '#ccc'; // Change text color based on input

        let currInput = oppNumber
        console.log("[FORMS] prev: ", prevInputRef.current, " curr: ", currInput, " index: ", input.selectionStart);
        // TRUE if user has added a character to the input
        if (input.selectionStart > inputPos.current) {
            if (inputPos.current === 8) { inputPos.current = inputPos.current + 2; }
            else { inputPos.current = inputPos.current + 1; }
        }
        // FALSE if user has deleted a character
        else {
            //if (inputPos.current === 10 || (inputPos.current === 14 && inputLength !== 14)){ inputPos.current = inputPos.current - 2; }
            inputPos.current = inputPos.current - 1;
        }

        input.value = oppNumber;
        if (inputPos.current === 0) {
            input.value = '00000000-0000';
        }
        setOppId('OPP-' + oppNumber);
        input.setSelectionRange(inputPos.current, inputPos.current);
        prevInputRef.current = currInput;

    }

    const handleSubmit = async () => {
        // Disable the submission buttons to not allow for double submission
        setIsSubmitting(true);
        // Do validation of the various fields to make sure it's ready to be sent away.
        let missingField = [];
        if (!formObj.number && isSalesForce) { missingField = [...missingField, "Opportunity ID, "] }
        if (!formObj.name && !isSalesForce) { missingField = [...missingField, "Opportunity Name, "] }
        if (!formObj.agreementType) { missingField = [...missingField, "Agreement Type, "] }
        if (!formObj.description && !isSalesForce) { missingField = [...missingField, "Description, "] }
        if (!formObj.clientLegalName && !isSalesForce) { missingField = [...missingField, "Client Account, "] }

        // Previously required fields
        //if (!formObj.totalValue && !isSalesForce) { missingField = [...missingField, "Total Opportunity Value, "] }

        if (missingField.length !== 0) {
            createWarningToast(
                <div>
                    <h4> Unable to Submit </h4>
                    <p>Missing required fields: <b>{missingField}</b> Please fill in the fields and resubmit.</p>
                </div>
            );
            // Renable submission button
            setIsSubmitting(false);
        }
        else {
            // Some mysterious step that's absolutely not implied by the previous step.
            console.log("[FORMS] Submitting opportunity");
            try {
                let resp = await apiServiceClient.Forms.submitOpportunity(formObj);
                console.log("[FORMS] Submitted, response: " + resp);
            }
            catch (e) {
                createWarningToast(
                    <div>
                        <h4> Unable to Submit Form</h4>
                        <p>This form failed to submit due to an internal error. Governance and Support teams have been alerted, please try again.</p>
                    </div>
                );
                // Renable submission button
                setIsSubmitting(false);
                return;
            }

            restartForm();
            navigate('/forms/completed');
        }
    }

    useEffect(() => {
        setFormObj({ ...formObj, "expedite": shouldBeExpedited })
    }, [shouldBeExpedited])

    return (
        <>
            {showForm ?
                <div className="o-page-section o-page-section l-mt-md l-pt-none">
                    <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                        <div className="l-row l-pt-sm">
                            <Heading size={1}>Governance Intake Form</Heading>
                            <p className="a-muted-text">Please fill out each field to the best of your ability. This completed form will be used for processing your request.</p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="o-block">
                                        {/* Opportunity ID */}
                                        {isSalesForce && <div className="m-form-group">
                                            <label htmlFor="number" className="a-label">Opportunity ID</label>
                                            <p className="a-subtle-text a-muted-text">Identification number for the currently selected opportunity.</p>
                                            <input
                                                id="number"
                                                type="text"
                                                className="a-text-input"
                                                value={formObj.number || ''}
                                                disabled={true}
                                            />
                                        </div>}
                                        {/* Opportunity Name */}
                                        <div className="m-form-group">
                                            <label htmlFor="name" className={isSalesForce ? "a-label" : "a-label a-label--required"}>Opportunity Name</label>
                                            <p className="a-subtle-text a-muted-text">This is the name assigned to the opportunity.</p>
                                            <input
                                                id="name"
                                                type="text"
                                                className="a-text-input"
                                                value={formObj.name || ''}
                                                onChange={(e) => updateData(e)}
                                                placeholder="The display name of the opportunity."
                                                title="The display name of the opportunity."
                                                disabled={isSalesForce}
                                            />
                                        </div>
                                        {/* Agreement Type */}
                                        <div className="m-form-group">
                                            <label htmlFor="agreementType" className="a-label a-label--required">Agreement Type</label>
                                            <p className="a-subtle-text a-muted-text">What is the form or vehicle for this opportunity? See the Glossary in the Governance Wiki for definitions.</p>
                                            <select id="agreementType" className="a-select-input" value={formObj.agreementType || ''} onChange={(e) => updateData(e)}>
                                                <option value={null} key={"test"}>Select an agreement...</option>
                                                {oppOptions.map((option) => {
                                                    return (
                                                        <option value={option} key={option}>{option}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        {/* Description */}
                                        <div className="m-form-group">
                                            <label htmlFor="description" className={isSalesForce ? "a-label" : "a-label a-label--required"}>Description</label>
                                            <p className="a-subtle-text a-muted-text">Update this field as more is learned about the opportunity so that this description can be used to provide a quick summary to executives. You can start by using narrative text from the CRM entry or from the email or its attachments. Useful information to include: award to single or multiple vendors, scope summary from the RFP, expected work start date, length of contract and extension.</p>
                                            <textarea
                                                id="description"
                                                className="a-textarea-input"
                                                rows="3"
                                                value={formObj.description || ''}
                                                onChange={(e) => updateData(e)}
                                                placeholder="Description of the opportunity. Be as specific as possible."
                                                title="Description of the opportunity. Be as specific as possible."
                                            />
                                        </div>
                                        {/* Total Opportunity Value */}
                                        <div className="m-form-group">
                                            <label htmlFor="totalValue" className={"a-label"}>Total Opportunity Value</label>
                                            <p className="a-subtle-text a-muted-text">Salesforce (Total Contract Value) which must match the Cost Model Extended Revenue. Leave blank if unknown.</p>
                                            <input
                                                id="totalValue"
                                                type="text"
                                                className="a-text-input"
                                                value={formObj.totalValue || ''}
                                                onChange={(e) => handleNumberChange(e)}
                                                placeholder="total value of the opportunity in U.S. dollars, if applicable"
                                                title="total value of the opportunity in U.S. dollars, if applicable"
                                                disabled={isSalesForce}
                                            />
                                        </div>
                                        {/* Client Account */}
                                        <div className="m-form-group">
                                            <label htmlFor="clientLegalName" className={isSalesForce ? "a-label" : "a-label a-label--required"}>Client Account</label>
                                            <p className="a-subtle-text a-muted-text">BUSINESS MANDATORY: The full legal name of the client entity.</p>
                                            <input
                                                id="clientLegalName"
                                                type="text"
                                                className="a-text-input"
                                                value={formObj.clientLegalName || ''}
                                                onChange={(e) => updateData(e)}
                                                placeholder="The full, legal name of the client entity."
                                                title="The full, legal name of the client entity."
                                                disabled={isSalesForce}
                                            />
                                        </div>
                                        {/* Request to Expedite */}
                                        <div className="m-form-group">
                                            <label htmlFor="expedite" className="a-label">Request to Expedite</label>
                                            <p className="a-subtle-text a-muted-text">A request to expedite the processing of the opportunity.</p>
                                            <div>
                                                <label className="a-checkbox">
                                                    <span className="a-checkbox__label">Yes</span>
                                                    <input
                                                        id="expediteYes"
                                                        type="radio"
                                                        name="expedite"
                                                        value="yes"
                                                        checked={shouldBeExpedited === true}
                                                        onChange={() => setShouldBeExpedited(true)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                                <label className="a-checkbox l-mt-xs">
                                                    <span className="a-checkbox__label">No</span>
                                                    <input
                                                        id="expediteNo"
                                                        type="radio"
                                                        name="expedite"
                                                        value="no"
                                                        checked={shouldBeExpedited === false}
                                                        onChange={() => setShouldBeExpedited(false)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* Summary Comments */}
                                        <div className="m-form-group">
                                            <label htmlFor="comments" className="a-label">Summary Comments</label>
                                            <p className="a-subtle-text a-muted-text">Comments from submitter or opportunity owner. Could include pasted text from email.</p>
                                            <textarea
                                                required
                                                id="comments"
                                                className="a-textarea-input"
                                                rows="5"
                                                value={formObj.comments || ''}
                                                onChange={(e) => updateData(e)}
                                                placeholder="Additional comments to add."
                                                title="Additional comments to add."
                                            />
                                        </div>
                                        {/* Teams Channel Name or URL */}
                                        <div className="m-form-group">
                                            <label htmlFor="teamsSite" className="a-label">Teams Channel Name or URL</label>
                                            <p className="a-subtle-text a-muted-text">Copy link from the Teams Channel and paste here in order to provide quick access to the documents to be reviewed.</p>
                                            <input
                                                id="teamsSite"
                                                type="text"
                                                className="a-text-input"
                                                value={formObj.teamsSite || ''}
                                                onChange={(e) => updateData(e)}
                                                placeholder="The name or link to the MS teams channel."
                                                title="The name or link to the MS teams channel."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ButtonRow className="l-mt-md">
                                <Button children="Back" disabled={isSubmitting} onClick={restartForm} outline />
                                {/* <Button children="Test" onClick={() => console.log("[FORM] Object:", formObj)} outline /> */}
                                <Button children="Submit" disabled={isSubmitting} onClick={handleSubmit} />
                            </ButtonRow>
                        </div>
                    </div>
                </div>
                :
                <div className="o-page-section o-page-section l-mt-md l-pt-none">
                    <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                        <div className="l-row l-pt-sm">
                            <Heading size={1}>Governance Intake Form</Heading>
                            <p className="a-muted-text">Please fill out each field to the best of your ability. This completed form will be used for processing your request.</p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="o-block">
                                        {/* Is Salesforce */}
                                        <div className="m-form-group">
                                            <label htmlFor="expedite" className="a-label">Is the opportunity in Salesforce?</label>
                                            <p className="a-subtle-text a-muted-text">A new field to provide the opportunity ID will show if this exists in Salesforce.</p>
                                            <div>
                                                <label className="a-checkbox">
                                                    <span className="a-checkbox__label">Yes</span>
                                                    <input
                                                        id="salesYes"
                                                        type="radio"
                                                        name="salesForce"
                                                        value="yes"
                                                        checked={isSalesForce === true}
                                                        onChange={() => setIsSalesForce(true)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                                <label className="a-checkbox l-mt-xs">
                                                    <span className="a-checkbox__label">No</span>
                                                    <input
                                                        id="salesNo"
                                                        type="radio"
                                                        name="salesForce"
                                                        value="no"
                                                        checked={isSalesForce === false}
                                                        onChange={() => {
                                                            setIsSalesForce(false);
                                                            setOppId(undefined);
                                                        }}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                            </div>
                                        </div>
                                        {isSalesForce && <div className="m-form-group l-mt-sm">
                                            <label htmlFor="" className="a-label">Opportunity ID</label>
                                            <div id="input-container" style={{ display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "4px" }}>
                                                <span className="a-subtle-text a-muted-text" style={{ marginRight: "4px", color: "black" }}>OPP-</span>
                                                <input
                                                    className="a-subtle-text a-muted-text"
                                                    type="text"
                                                    id="opp-input"
                                                    placeholder="00000000-0000"
                                                    style={{
                                                        flexGrow: 1,
                                                        border: "none",
                                                        color: "black",
                                                        padding: "4px",
                                                        outline: "none"
                                                    }}
                                                    onChange={(e) => { handleInput(e.target); e.preventDefault(); }}
                                                    onFocus={(e) => { handleCursor(e.target); e.preventDefault(); }}
                                                    onClick={(e) => { handleCursor(e.target); e.preventDefault(); }}
                                                    onSelect={(e) => { handleCursor(e.target); e.preventDefault(); }}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <ButtonRow className="l-mt-md">
                                <Button children="Continue" onClick={handleOppId} />
                            </ButtonRow>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default GovernanceForms;