import React, { createContext, useContext, useEffect, useState } from "react";
import { useAdminApiService } from "../hooks/useAdminApi";
import { PROD_allowedDbReseters, PROD_allowedImpersonators, TEST_allowedDbReseters, TEST_allowedImpersonators } from '../constants/constants';
import { useAuthentication } from '../contexts/AuthContext';

const AppConfigContext = createContext();

function AppConfigProvider({ children }) {

    const apiServiceClient = useAdminApiService();
    // Reference to the root styles so we can edit them when colors are loaded.
    const rootStyle = document.documentElement.style;

    const [brandLogo, setBrandLogo] = useState(null)
    const [isProd, setIsProd] = useState(false);
    const [applicationPlatform, setApplicationPlatform] = useState("");
    const [impersonateAvailable, setImpersonateAvailable] = useState(false);
    const [resetDbAvailable, setResetDbAvailable] = useState(false);
    const { graphData, impersonating } = useAuthentication();

    const [hideImpersonateModal, setHideImpersonateModal] = useState(true);

    useEffect(() => {
        console.log("[COLORS] Updating colors...");
        updateColors();
    }, [])
    
    // Used to determine the platform the code is being run on
    useEffect(() => {
        if (graphData) {
            const currentHref = window.location.href;
            const isDev = currentHref.includes("mycai-dev") || currentHref.includes("localhost:");
            const isTest = currentHref.includes("testmy.cai.io") || currentHref.includes("mycai-test");
            const isProd = !isDev && !isTest;

            if (isDev || isTest) {
                console.log("[DEBUG] Hello from test environment! If you are seeing this on prod, then uh oh");
                console.log(`This is the ${isDev ? "local" : "development"} environment.`);
            }

            setIsProd(isProd);
            setApplicationPlatform(isProd ? "production" : isTest ? "test" : "develop");

            const checkPermissions = (allowedList) => 
                allowedList.map((x) => x.toLowerCase()).includes(graphData.displayName.toLowerCase());

            if (isDev || impersonating) {
                setImpersonateAvailable(true);
                setResetDbAvailable(true);
            } else if (isTest) {
                setImpersonateAvailable(checkPermissions(TEST_allowedImpersonators));
                setResetDbAvailable(checkPermissions(TEST_allowedDbReseters));
            } else if (isProd) {
                setImpersonateAvailable(checkPermissions(PROD_allowedImpersonators));
                setResetDbAvailable(checkPermissions(PROD_allowedDbReseters));
            } else {
                setImpersonateAvailable(false);
                setResetDbAvailable(false);
            }
        } else {
            setImpersonateAvailable(false);
            setResetDbAvailable(false);
        }
    }, [impersonating, graphData]);

    const updateColors = async () => {
        let colors = await apiServiceClient.Styles.getColors()
        console.log("[COLORS] Updating local colors:", colors);


        if(process.env.REACT_APP_HARDCODE_STYLES !== "yes"){
            // Grab the list of all fields.
            let colorKeys = Object.keys(colors);
            // For each field, update the CSS variable with the same value.
            colorKeys.forEach(element => {
                //console.log("[COLORS] Changing", element, "to", colors[element]);
                rootStyle.setProperty(("--color-" + element), colors[element]);
            });
        }
    }

    const getLogo = async () => {

    }

    return (
		<AppConfigContext.Provider
			value={{
                // States
                brandLogo,
                isProd,
                applicationPlatform,
                impersonateAvailable,
                resetDbAvailable,
                hideImpersonateModal,
                setHideImpersonateModal,
                // Functions
                updateColors,
                getLogo,
			}}
		>
			{children}
		</AppConfigContext.Provider>
	);
}

// Hook to use the ChatContext in a component
function useAppConfig() {
	const context = useContext(AppConfigContext);
	if (context === undefined) {
		throw new Error(
			"useAppConfig must be used within a AppConfigProvider. I seriously do not know how you got this error."
		);
	}
	return context;
}

export { AppConfigProvider, useAppConfig };