export const PROD_allowedDbReseters = ["Christopher Zumberge"];
export const PROD_allowedImpersonators = ["Christopher Zumberge", "Melissa Michael", "Tammy Harper"];


export const TEST_allowedDbReseters = [
    "Melissa Michael",
    "Tammy Harper",
    "Kelly Butz",
    "Christopher Zumberge",
    "Alex Noel",
];

export const TEST_allowedImpersonators = [
    "Melissa Michael",
    "Tammy Harper",
    "Kelly Butz",
    "Christopher Zumberge",
];


const imgLogo = require("../images/myCAI-white.svg").default;
const imgGo = require("../images/icon-go-white.svg").default;
const imgAppEngagementReporting = require("../images/app-engagement-reporting.svg").default;
const imgAppPayrollPlanning = require("../images/app-payroll.svg").default;
const imgAppSift = require("../images/app-sift.svg").default;
const imgAppTwilio = require("../images/app-twilio.svg").default;
const imgAppSettings = require("../images/icon-settings-white.svg").default;


export const myCAIApplications = [
    // {
    //     path: "/payroll",
    //     imgSrc: imgAppPayrollPlanning,
    //     appName: "Payroll Planning",
    // },
    // {
    //   path: "/bonus",
    //   imgSrc: imgAppPayrollPlanning,
    //   appName: "Bonus Planning",
    // },
    // {
    //   path: "/sift",
    //   imgSrc: imgAppSift,
    //   appName: "Sift",
    // },
    // {
    //   path: "/communications",
    //   imgSrc: imgAppTwilio,
    //   appName: "Communications Admin (Twilio)",
    // },
    // {
    //   path: "/enterprisereporting",
    //   imgSrc: imgAppEngagementReporting,
    //   appName: "Enterprise Reporting",
    // },
    // {
    //   path: "/engagementreporting",
    //   imgSrc: imgAppEngagementReporting,
    //   appName: "Engagement Reporting",
    // },
    // {
    //     path: "/cainlp/playground",
    //     imgSrc: imgAppEngagementReporting,
    //     appName: "CAI NLP",
    // },
    {
        path: "/symphony",
        imgSrc: imgAppTwilio,
        appName: process.env.REACT_APP_BRANDING_SYMPHONY_NAME ?? "Symphony",
    },
    {
        path: "/settings/symphony",
        imgSrc: imgAppSettings,
        appName: "My Settings"
    },
    // {
    //     path: "/forms/governance",
    //     imgSrc: imgAppSettings,
    //     appName: "Forms"
    // }
];
