import React, { Fragment, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    NavLink,
    useParams,
    Routes,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
const icon_CloseSquare = require("../../images/close-square.svg").default;
const icon_Search = require("../../images/search.png");
const annualizedHours = 1880;
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export const PayrollMeritChangeBulkActionModal = (props) => {
    const [selected, setSelected] = useState(props.selected);
    const [increasePercentage, setIncreasePercentage] = useState(0);
    const [remainingAllocation, setRemainingAllocation] = useState(props.remainingAllocation)
    const [newRemainingAllocation, setNewRemainingAllocation] = useState(props.remainingAllocation)
    const [curComp, setCurComp] = useState(0.0);
    const [propComp, setPropComp] = useState(0.0);
    const [changesMade, setChangesMade] = useState(false);
    const [mode, setMode] = useState("percent");
    const [increaseAmt, setIncreaseAmt] = useState(0.0);
    const [ccId, setCcId] = useState(props.ccId);

    const navigate = useNavigate();

    const getCurPropComp = () => {
        var comp = 0;
        var propComp = 0;

        selected?.map((x) => {
            if (x.curCompGrade == "Hourly") {
                comp += x.curBaseComp * annualizedHours * x.ftePercentage;
                propComp += x.curBaseComp * (1 + increasePercentage / 100) * annualizedHours * x.ftePercentage;
            } else {
                comp += x.curBaseComp;
                propComp += x.curBaseComp * (1 + increasePercentage / 100);
            }

        })
        console.log(comp);
        console.log(propComp);
        setCurComp(comp);
        setPropComp(propComp);
        setIncreaseAmt((propComp - comp));
    }

    const prepObj = () => {
        var obj = []
        selected?.map((x) => {
            obj.push({
                meritChangeId: x.id,
                // newCompAmt: x.curBaseComp * (1 + increasePercentage / 100)
                newCompAmt: increasePercentage,
                updatedBy: x.assignedTo,
                updatedEmployee: x.upn,
                propBaseComp: x.curBaseComp * (1 + increasePercentage / 100)
            })
        })

        return obj;
    }

    useEffect(() => {
        if (props.hidden === false) {
            setSelected(props.selected);
            setRemainingAllocation(props.remainingAllocation);
            setNewRemainingAllocation(props.remainingAllocation);
            setCcId(props.ccId);
        }

        /*
        if (!(selected?.length > 0)) {
            props.onCancel();
        }
        */
    }, [props.hidden]);

    useEffect(() => {
        getCurPropComp();
        setIncreasePercentage(0.0);
    }, [selected])

    const handleChange = (e) => {
        var val = e.target.value;
        const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (validated) {
            setChangesMade(true);
            if (mode == "percent") {
                setIncreasePercentage(val);
                setIncreaseAmt((propComp - curComp) - (curComp * val / 100));
            } else if (mode == "valueIncrease") {
                setIncreaseAmt(propComp - val);
                setIncreasePercentage(val / curComp);
            } else {
                console.log("No mode matched %s", mode);
            }
        }
    }

    const closeClickedHandler = (changesMade) => {
        if (props.onClose) {
            props.onClose(changesMade);
        }
    };

    return (
        <div className={`o-modal ${props.hidden ? 'o-modal--hidden' : 'o-modal--shown'}`} id="exampleModal">
            <div className="o-modal__overlay" onClick={(e) => {
                if (props.clickOutsideToClose)
                {
                    props.onClose(changesMade);
                }
                e.preventDefault();
            }}></div>
            <div className="o-modal__dialog o-modal__dialog--wide">
                <div className="o-modal__content">
                    <div className="o-modal__header">
                        <h3 className="o-modal__title">Bulk Change</h3>
                        <div className="m-icon-row m-icon-row--right-align">
                            <a
                                href="#"
                                id="bulkActionsCloseBtn"
                                className="a-icon m-icon-row__icon"
                                title="Close Bulk Actions"
                                onClick={(e) => {
                                    closeClickedHandler(changesMade);
                                    e.preventDefault();
                                }}
                            >
                                <img src={icon_CloseSquare} className="a-icon__img" />
                            </a>
                        </div>
                    </div>
                    <div className="o-modal__body">
                        <div className="l-row">
                            <div className="l-one-col">
                                <p>{`What percentage increase would you like to specify for the selected ${selected.length} employees?`}</p>
                            </div>
                        </div>
                        <div className="l-row l-mt-sm">
                            <div className="l-one-thirds-col">
                                <form action="" className="o-form">
                                    <div className="m-form-group l-mt-xs">
                                        <label htmlFor="" className="a-label">Percentage Increase</label>
                                        <input
                                            placeholder="Enter a percentage value"
                                            type="number"
                                            name="Increase Input"
                                            className="a-text-input"
                                            value={
                                                Math.round(increasePercentage * 100 + Number.EPSILON) /
                                                100
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="l-two-thirds-col">
                                <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                    <colgroup className="o-treegrid__colgroup">
                                        <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                        <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                    </colgroup>
                                    <tbody>
                                        <tr className="o-treegrid__row o-treegrid__row--shown o-treegrid__row--no-border">
                                            <td className="o-treegrid__cell">
                                                <span className="a-subtle-text">Budget remaining before change</span>
                                            </td>
                                            <td className="o-treegrid__cell  o-treegrid__cell--right-aligned">
                                                <span className="a-positive-text">{formatter.format(remainingAllocation)}</span>
                                            </td>
                                        </tr>
                                        <tr className="o-treegrid__row o-treegrid__row--shown">
                                            <td className="o-treegrid__cell">
                                                <span className="a-subtle-text">Cost of this change
                                                </span>
                                            </td>
                                            <td className="o-treegrid__cell  o-treegrid__cell--right-aligned">{formatter.format(increaseAmt)}
                                            </td>
                                        </tr>
                                        <tr className="o-treegrid__row o-treegrid__row--shown o-treegrid__row--no-border">
                                            <td className="o-treegrid__cell">
                                                <span className="a-subtle-text">Budget remaining after change</span>
                                            </td>
                                            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                                <span className={(remainingAllocation + increaseAmt) >= 0 ?"a-positive-text" : "a-warning-text"}>{formatter.format(remainingAllocation + increaseAmt)}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="o-modal__footer">
                        <div className="m-button-row">
                            <a href="#" className="m-button-row__button a-button a-button--outline" onClick={(e) => { props.onCancel(changesMade); e.preventDefault(); }}>Cancel</a>
                            <a href="#" className="m-button-row__button a-button" onClick={(e) => { props.onSave(prepObj()); e.preventDefault(); }}>Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
